import React, { useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import InputField from '../../../components/InputField';
import { useForm } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';

const RegisterCanteenPage = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    // Global
    const axiosPrivate = useAxiosPrivate();
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [, setCookie] = useCookies({});

    // Form
    const { refCode, ...inputPropsCode } = register('code', {
        required: 'Code obligatoire',
    });

    useEffect(() => {
        // Redirect if choice already made
        if (auth?.canteenId !== null || auth?.childId !== null) {
            navigate('/');
        }
    }, [])

    // Call Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const result = await axiosPrivate.post('/guests/canteen', {
                canteen_code: data.code,
            }, {withCredentials: true});

            setAuth({ ...auth, canteenId: result.data.defaultCanteen.id });
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, canteenId: result.data.defaultCanteen.id }, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, {'id': result.data.defaultCanteen.id, 'name': result.data.defaultCanteen.name}, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, true, {path: '/'})
            navigate('/tutorial');
        } catch (err) {
            let error;
            if (err?.response?.status === 404) {
                error = 'Le code Cantine est incorrect';
                setFlash({ message: error });
                setShowFlash(true);
            } else {
                error = 'Erreur Serveur';
                navigate('/500');
                setFlash({ message: error });
                setShowFlash(true);
            }
        }

        // Loader
        setIsLoading(false);
    }

    // const modal = async () => {
    //     setModal({
    //         title: <div style={{backgroundColor: '#0B50A4', color: '#fff'}} className='d-flex justify-content-center align-items-center flex-column py-2 mb-3'>Renseigner mon code Cantine,</div>,
    //         content: <div className='d-flex justify-content-center align-items-center flex-column px-4'>
    //             <p>Le code cantine vous permet de lier votre compte à votre cantine et de pouvoir accéder à ses menus</p>
    //             <p>Il est communiqué par votre cantine et est accessible dans votre établissement</p>
    //         </div>
    //     })
    //     setShowModal(true)
    // }


    return (
        <>
            <div className='profil-page d-flex min-vh-100 justify-content-center'>
                <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                    <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                        <div className='d-flex profile-header-back'>
                            <Link to='/register-model'>
                                <span style={{backgroundColor: '#F5F4ED', borderRadius: '20px', padding: '10px' }}>
                                    <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        </div>
                        <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Bonjour {auth?.firstName}</div>
                        <div><img className='logo-tiny' src='/images/logo-tiny.png' alt='Jabu'></img></div>
                    </div>

                    <div className='d-flex flex-column profile-block flex-grow-1'>
                        <div className='user-title d-flex justify-content-center'>
                            Cantine de référence
                        </div>

                        <form style={{margin: '50px 0'}} className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
                            <div className="d-flex align-items-center justify-content-center flex-grow-1 flex-column">
                                <InputField color="#000" label="Code Cantine *" className="user-input" error={!!errors.code} helper={errors?.code?.message} inputRef={refCode} inputProps={inputPropsCode}></InputField>
                            </div>

                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <p className='w-100' style={{'fontWeight': 'bold', 'marginTop': '30px'}}>Code cantine ?</p>
                                <p>Le code cantine vous permet de lier votre compte à votre cantine et de pouvoir accéder à ses menus.</p>
                                <p>Il est communiqué par votre cantine et est accessible dans votre établissement.</p>
                            </div>

                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <input className='user-submit' type='submit' value='Suivant'></input>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
};

export default RegisterCanteenPage;
